import { browser } from "$app/environment"
import { Capacitor } from "@capacitor/core"
import { Keyboard } from "@capacitor/keyboard"
import { ScreenOrientation, type ScreenOrientationResult } from "@capacitor/screen-orientation"
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Subject, tap } from "rxjs"
import { UAParser } from "ua-parser-js"

/* ============================================================================================== */
/*                                           Debug Mode                                           */
/* ============================================================================================== */

export const DEBUG$ = new BehaviorSubject(false)

/* =================================== Background / Foreground ================================== */

/** Native app will come back into foreground. */
export const resume$ = new Subject<void>()

/* ========================================= Fullscreen ========================================= */

export const isFullscreen$ = new BehaviorSubject(false)

browser &&
    document.addEventListener("fullscreenchange", () =>
        isFullscreen$.next(document.fullscreenElement !== null),
    )

/* ========================================== Platform ========================================== */

const os = new UAParser().getOS().name

/** The platform on which the app is run, influences the style of some components. */
export const platform: "desktop" | "mobile" =
    (browser && localStorage.getItem("MOBILE")) || // allow setting 'MOBILE' to force mobile platform
    Capacitor.isNativePlatform() ||
    os?.includes("iOS") ||
    os?.includes("ios") ||
    os?.includes("Android") ||
    os?.includes("android")
        ? "mobile"
        : "desktop"

/** Is a natively running Capacitor app on iOS or Android. */
export const isMobileNativeApp = Capacitor.isNativePlatform()

/* ========================================= Overscroll ========================================= */

//  @feat: min-h-overscroll
if (browser && platform === "mobile") {
    // if `<8px` then iOS does not show scrollbars
    document.documentElement.style.setProperty("--min-h-overscroll-additional", "1px")
}

/* ========================================== Keyboard ========================================== */

/**
 * If a mobile keyboard is currently shown on screen,
 * that resizes the window and should hide the TabBar.
 */
export const isKeyboardShown$ = new BehaviorSubject(false)
// Used by scrolling containers
export const keyboardWillShow$ = new Subject<void>()
export const keyboardWillHide$ = new Subject<void>()
export const keyboardDidShow$ = new Subject<void>()
export const keyboardDidHide$ = new Subject<void>()

if (browser && Capacitor.isPluginAvailable("Keyboard")) {
    Keyboard.addListener("keyboardWillShow", () => {
        keyboardWillShow$.next()
        isKeyboardShown$.next(true)
    })
    Keyboard.addListener("keyboardWillHide", () => {
        // if no delay, then the TabBar will show above the keyboard position briefly
        setTimeout(() => {
            keyboardWillHide$.next()
            isKeyboardShown$.next(false)
        }, 100)
    })
    Keyboard.addListener("keyboardDidShow", () => {
        keyboardDidShow$.next()
    })
    Keyboard.addListener("keyboardDidHide", () => {
        keyboardDidHide$.next()
    })
}

/* ========================================= Screen Size ======================================== */

const LARGE_WIDTH_WIDER_THAN = 1024 // px
const SMALL_HEIGHT_LESS_THAN = 512 // px

// If the window is wide enough to allow the `with-sidebar` layout
const isLargeWidth$ = new BehaviorSubject(false)
// If the window is not very high, the NavBar and TabBar should be hidden in `no-sidebar-landscape` layout
const isSmallHeight$ = new BehaviorSubject(false)

function setLargeWidth(sidebar: boolean) {
    // console.log('[Layout] setLargeWidth', sidebar)
    if (isLargeWidth$.value !== sidebar) {
        isLargeWidth$.next(sidebar)
    }
}

function setSmallHeight(smallHeight: boolean) {
    // console.log('[Layout] setSmallHeight', smallHeight)
    if (isSmallHeight$.value !== smallHeight) {
        isSmallHeight$.next(smallHeight)
    }
}

if (browser) {
    setLargeWidth(window.innerWidth > LARGE_WIDTH_WIDER_THAN)
    setSmallHeight(window.innerHeight < SMALL_HEIGHT_LESS_THAN)

    window.addEventListener("resize", () => {
        setLargeWidth(window.innerWidth > LARGE_WIDTH_WIDER_THAN)
        setSmallHeight(window.innerHeight < SMALL_HEIGHT_LESS_THAN)
    })
}

/* ========================================= Horizontal ========================================= */

/** A basic boolean if in landscape mode */
const isHorizontal$ = new BehaviorSubject(false)
/** A description of what landscape/portrait mode we are in */
export const orientation$ = new BehaviorSubject<OrientationType>("portrait-primary")

function setHorizontal(horizontal: boolean) {
    console.log("[Layout] setHorizontal", horizontal)
    if (isHorizontal$.value !== horizontal) {
        isHorizontal$.next(horizontal)
        console.info("[Layout] isHorizontal$ =", horizontal)
    }
}

function setOrientation(orientation: ScreenOrientationResult) {
    console.log("[Layout] setOrientation", orientation)
    switch (orientation.type) {
        case "portrait-primary": {
            setHorizontal(false)
            orientation$.next("portrait-primary")
            return
        }
        case "portrait-secondary": {
            // give a delay before checking the screen dimensions,
            // because on iPad Simulator at this moment the height&width are still from before the rotation
            // `10ms` was observed to be too little
            setTimeout(() => {
                // assert that the orientation is indeed portrait,
                // because on iPhone for example the inverse portrait orientation is locked
                if (window.innerHeight > window.innerWidth) {
                    setHorizontal(false)
                    orientation$.next("portrait-secondary")
                }
            }, 50)
            return
        }
        case "landscape-primary": {
            setHorizontal(true)
            orientation$.next("landscape-primary")
            return
        }
        case "landscape-secondary": {
            setHorizontal(true)
            orientation$.next("landscape-secondary")
            return
        }
    }
}

if (browser && Capacitor.isPluginAvailable("ScreenOrientation")) {
    ScreenOrientation.orientation().then((orientation) => setOrientation(orientation))

    ScreenOrientation.addListener("screenOrientationChange", (orientation) => {
        // console.log('[Layout] screenOrientationChange', orientation)
        setOrientation(orientation)
    })
}

/* ======================================= Derived Layouts ====================================== */

/** The three main layouts. */
export const layout$ = new BehaviorSubject<"with-sidebar" | "no-sidebar" | "no-sidebar-landscape">(
    "with-sidebar",
)

combineLatest([isLargeWidth$, isHorizontal$])
    .pipe(
        map(([isLargeWidth, isHorizontal]) => {
            return isLargeWidth
                ? "with-sidebar"
                : // check platform, because on desktop the default orientation is 'landscape-primary'
                  isHorizontal && platform === "mobile"
                  ? "no-sidebar-landscape"
                  : "no-sidebar"
        }),
        distinctUntilChanged(),
        tap((layout) => console.info("[Layout] layout$ =", layout)),
    )
    .subscribe(layout$)

/** In landscape mode, when height is small, hide the NavBar */
export const showNavBar$ = // new BehaviorSubject(true)
    combineLatest([layout$, isSmallHeight$]).pipe(
        map(([layout, isSmallHeight]) => {
            return layout === "no-sidebar-landscape" && isSmallHeight ? false : true
        }),
        distinctUntilChanged(),
    )

/** In landscape mode, when height is small OR when the keyboard is shown on mobile, hide the TabBar */
export const showTabBar$ = // new BehaviorSubject(true)
    combineLatest([layout$, isSmallHeight$, isKeyboardShown$]).pipe(
        map(([layout, isSmallHeight, isKeyboardShown]) => {
            return !(layout === "no-sidebar-landscape" && isSmallHeight) && !isKeyboardShown
        }),
        distinctUntilChanged(),
    )
